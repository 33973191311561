.landing-page {
  background: linear-gradient(rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), url("../../../public/wallpaper.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

@media (max-height: 425px) {
  .test p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}
